@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?30538159');
  src: url('../font/fontello.eot?30538159#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?30538159') format('woff2'),
       url('../font/fontello.woff?30538159') format('woff'),
       url('../font/fontello.ttf?30538159') format('truetype'),
       url('../font/fontello.svg?30538159#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon-star:before { content: '\2605'; }
.icon-star-empty:before { content: '\2606'; }
.icon-star-half:before { content: '\e701'; }
.icon-star-half-alt:before { content: '\f123'; }
